<template>
	<div class="partial partial--artistList">
		<artists></artists>
	</div>
</template>

<script>
// Components
import Artists from '@/components/Artists'

// Export
export default {
	name: 'artistList',
	components: {
		Artists,
	},
	metaInfo() {
		return {
			title: 'Artist Name',
			titleTemplate: '%s | Mike Parsons Ink',
		}
	},
}
</script>

<style lang="scss" scoped>

</style>
